
// divide the array into sub array based on the object's key
export const groupBy = (arr, key_getter) => {
  const map = new Map();
  arr.forEach((se) => {
       const key = key_getter(se);
       const collection = map.get(key);
       if (!collection) {
           map.set(key, [se]);
       } else {
           collection.push(se);
       }
  });
  return map;
}

// count the number of times the user retried the same scenario
export const countRetries = (user_scenarios, dates, users_num) => {
  let graphData = [];
  for (const date of dates) {
    const user_scenarios_in_date = user_scenarios.get(date);
    const user_scenarios_by_scenario = groupBy(user_scenarios_in_date, us => us.scenario.name);
    let retry_count = 0;
    for (const entry of user_scenarios_by_scenario.entries()) {
      retry_count += entry[1].length - 1;
    }
    retry_count /= users_num;
    graphData.push({"name": date, "Conversation Count": retry_count});
  }
  return graphData;
};

// count all the given skill events by type
export const getAllSkillEvents = (pos_skill_events, cue_skill_events) => {
  const pos_skill_events_by_skill = groupBy(pos_skill_events, se => se.skill);
  let pos_sum = 0;
  for (const entry of pos_skill_events_by_skill.entries()) {
    pos_sum += entry[1].length;
  }
  const cue_skill_events_by_skill = groupBy(cue_skill_events, se => se.skill);
  let cue_sum = 0;
  for (const entry of cue_skill_events_by_skill.entries()) {
    cue_sum += entry[1].length;
  }
  const pos_skill_num = pos_skill_events_by_skill.size;
  let pos_num = pos_skill_num > 0 ? pos_sum / pos_skill_num : 0;
  const cue_skill_num = cue_skill_events_by_skill.size;
  let cue_num = cue_skill_num > 0 ? cue_sum / cue_skill_num : 0;
  return [pos_num, cue_num];
}

export const fillNullDates = (graphDataArr, existingDates, graph) => {
  const startYear = parseInt(existingDates[0].substring(6));
  const startMonth = parseInt(existingDates[0].substring(3,5)) - 1;
  const startDay = parseInt(existingDates[0].substring(0,2));
  const startDate = new Date(startYear, startMonth, startDay);
  let count = 0;
  for (const date=startDate; date<=Date.now(); date.setDate(date.getDate() + 1)) {
    const dateStr = date.toLocaleDateString("en-GB");
    if (!existingDates.includes(dateStr)) {
      let graphEntry;
      if (graph === "Progress") {
        graphEntry = {"name": dateStr, "Positive Feedback": 0, "Cues": 0 };
      } else if (graph === "Conversation") {
        graphEntry = {"name": dateStr, "Conversation Count": 0 };
      } else if (graph === "ConversationTurns") {
        graphEntry = {"name": dateStr, "Average": 0 };
      }
      graphDataArr.splice(count, 0, graphEntry);
    }
    count += 1;
  }
};
