import { Article, ExpandMore } from "@mui/icons-material";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
} from "@mui/material";
import { Parser } from "html-to-react";
import InfoPageDetailsPopup from "./InfoPageDetailsPopup";
import { useState } from "react";

const InfoPageInList = ({ infoPage }) => {
  const [editingInfoPage, setEditingInfoPage] = useState(false); 

  const saveInfoPage = (name, content) => {
    setTimeout(() => console.log(`saved info page, name: ${name}, content: ${content}` ))
  }

  return (
    <>
      <Accordion
        className="staff-course-in-list-lesson"
        style={{
          margin: "10px 0",
          background: "#f9f871",
        }}
      >
        <AccordionSummary expandIcon={<ExpandMore />}>
          <Typography className="staff-course-in-list-lesson-name">
            <Article /> INFO PAGE: {infoPage.name}
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <div
            className="staff-course-in-list-info-page"
            style={{ background: "white" }}
          >
            {Parser().parse(infoPage.content)}
          </div>
          <div className="staff-course-in-list-edit-button-wrapper">
            <button
              className="prompt-generation-button"
              onClick={() => setEditingInfoPage(true)}
            >
              EDIT INFO PAGE "{(infoPage?.name ?? "").toUpperCase()}
              ""
            </button>
          </div>
        </AccordionDetails>
      </Accordion>
      <InfoPageDetailsPopup
        open={editingInfoPage}
        close={() => setEditingInfoPage(false)}
        infoPage={infoPage}
        save={saveInfoPage}
      />
    </>
  );
};

export default InfoPageInList;
