import {
  Article,
  Class,
  ExpandMore,
  Info,
  QuestionAnswer,
  SwapVert,
} from "@mui/icons-material";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  IconButton,
  Typography,
} from "@mui/material";
import InfoPageInList from "./InfoPageInList";
import ScenarioInList from "./ScenarioInList";
import UnitDetailsPopup from "./UnitDetailsPopup";
import { useEffect, useState } from "react";
import ReorderContentPopup from "./ReorderContentPopup";
import axios from "axios";
import { URL } from "../../../tools/url";
import useErrorPopup from "../../../tools/hooks/showError";

const UnitInList = ({ unit, updateUnit }) => {
  const [content, setContent] = useState([]);

  const [editingUnit, setEditingUnit] = useState(false);
  const [newItemName, setNewItemName] = useState("");
  const [savingNew, setSavingNew] = useState(false);
  const [newItemType, setNewItemType] = useState("");

  const [savingUnitData, setSavingUnitData] = useState(false);

  const [reordering, setReordering] = useState(false);

  const [showError, ErrorPopupComponent] = useErrorPopup();

  const saveUnit = async (unitData) => {
    setSavingUnitData(true);
    try {
      const { data } = await axios.post(`${URL}/api/ceSaveUnit/`, {
        ...unitData,
      });
      updateUnit(data);
    } catch (err) {
      console.error(err);
      showError("Couldn't save unit");
    }
    setSavingUnitData(false);
  };

  const updateContent = (contentType, contentData, isNew = false) => {
    let updateItem = { ...unit };
    const key = contentType === "info-page" ? "info_pages" : "scenarios";
    updateItem[key] = isNew
      ? [...updateItem[key], contentData]
      : updateItem[key].map((item) => {
          if (item.id === contentData.id) return contentData;
          return item;
        });
    updateUnit({
      ...updateItem,
    });
  };

  const saveNewItem = async () => {
    setSavingNew(true);
    try {
      const { data } = await axios.post(
        `${URL}/api/ceSave${
          newItemType === "info-page" ? "InfoPage" : "Scenario"
        }/`,
        {
          name: newItemName,
          order: content.length + 1,
          parent_unit_id: unit.id,
        }
      );
      updateContent(newItemType, data, true);
      setNewItemName("");
      setNewItemType("");
    } catch (err) {
      console.error(err);
      showError("Couldn't save unit");
    }
    setSavingNew(false);
  };

  useEffect(() => {
    setContent(
      [
        ...(unit?.info_pages?.map((item) => {
          return {
            ...item,
            type: "info-page",
          };
        }) ?? []),
        ...(unit?.scenarios?.map((item) => {
          return {
            ...item,
            type: "scenario",
          };
        }) ?? []),
      ].sort((a, b) => (a.order > b.order ? 1 : -1))
    );
  }, [unit]);

  return (
    <>
      <Accordion
        key={unit.id}
        className="staff-course-in-list-unit"
        style={{ margin: "10px 0", background: "#ff9ac8" }}
      >
        <AccordionSummary expandIcon={<ExpandMore />}>
          <Typography className="staff-course-in-list-unit-name">
            <Class /> UNIT: {unit.name}
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <div className="staff-course-in-list-details">
            <div
              style={{
                margin: "5px 0",
                fontWeight: "bold",
                textDecoration: "underline",
              }}
            >
              DETAILS
            </div>
            <div
              className="staff-course-in-list-description"
              style={{ background: "#ffcefb" }}
            >
              <Info />{" "}
              {unit.description ?? (
                <i style={{ fontStyle: "italic" }}>No description</i>
              )}
            </div>
          </div>
          <div className="staff-course-in-list-edit-button-wrapper">
            <button
              className="prompt-generation-button"
              onClick={() => setEditingUnit(true)}
            >
              EDIT DETAILS FOR UNIT "{(unit?.name ?? "").toUpperCase()}"
            </button>
          </div>
          <div className="staff-course-in-list-lessons">
            <div
              style={{
                margin: "15px 0 5px 0",
                fontWeight: "bold",
                textDecoration: "underline",
              }}
            >
              CONTENT
            </div>
            {content.map((lesson) => (
              <div
                key={`${lesson.type}${lesson.id}`}
                className="staff-course-in-list-lesson"
              >
                <div>
                  {lesson.type === "info-page" ? (
                    <InfoPageInList
                      infoPage={lesson}
                      key={`info-page-${lesson.id}`}
                    />
                  ) : (
                    <ScenarioInList
                      scenario={lesson}
                      key={`scenario-${lesson.id}`}
                    />
                  )}
                </div>
              </div>
            ))}
            <IconButton
              title="Reorder content"
              onClick={() => setReordering(true)}
              style={{
                width: "30px",
                height: "30px",
                color: "white",
                background: "#262D3B",
              }}
            >
              <SwapVert />
            </IconButton>
            <div
              className="staff-course-in-list"
              style={{
                margin: "10px 0",
                background:
                  newItemType === "info-page"
                    ? "#f9f871"
                    : newItemType === "scenario"
                    ? "#ffd171"
                    : "#ff9ac8",
                display: "flex",
                alignItems: "center",
                height: "50px",
                padding: "0 15px",
              }}
            >
              {newItemType ? (
                <div
                  className="staff-course-in-list-name"
                  style={{ display: "flex", alignItems: "center" }}
                >
                  {newItemType === "info-page" ? (
                    <Article />
                  ) : (
                    <QuestionAnswer />
                  )}
                  <input
                    placeholder={`Enter ${
                      newItemType === "info-page" ? "info page" : "scenario"
                    } name...`}
                    value={newItemName}
                    onChange={(e) => setNewItemName(e.target.value)}
                    disabled={savingNew}
                    style={{ width: "750px" }}
                  ></input>
                  {savingNew ? (
                    <div
                      style={{
                        width: "200px",
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      <div
                        className="loader prompt-generation-loader"
                        style={{ height: "35px" }}
                      >
                        <div id="bar1" className="bar"></div>
                        <div id="bar2" className="bar"></div>
                        <div id="bar3" className="bar"></div>
                      </div>
                    </div>
                  ) : (
                    <>
                      <button
                        className="prompt-generation-button"
                        style={{
                          margin: "0 5px 0 0",
                          padding: "5px 10px",
                          background: false ? "#ccc" : "#0ae5a1",
                          border: "1px solid #282c34",
                          color: "#282c34",
                        }}
                        disabled={false}
                        onClick={() => saveNewItem()}
                      >
                        SAVE
                      </button>
                      <button
                        className="prompt-generation-button"
                        style={{
                          margin: 0,
                          padding: "5px 10px",
                          background: "#fa7d5f",
                          border: "1px solid #282c34",
                          color: "#282c34",
                        }}
                        onClick={() => {
                          setNewItemType("");
                          setNewItemName("");
                        }}
                      >
                        CANCEL
                      </button>
                    </>
                  )}
                </div>
              ) : (
                <>
                  <button
                    className="prompt-generation-button"
                    style={{
                      margin: 0,
                      padding: "5px 10px",
                    }}
                    onClick={() => {
                      setNewItemType("info-page");
                    }}
                  >
                    ADD A NEW INFO PAGE
                  </button>
                  <button
                    className="prompt-generation-button"
                    style={{
                      margin: "0 5px",
                      padding: "5px 10px",
                    }}
                    onClick={() => {
                      setNewItemType("scenario");
                    }}
                    disabled //TODO change when server side is done
                  >
                    ADD A NEW SCENARIO
                  </button>
                </>
              )}
            </div>
          </div>
        </AccordionDetails>
      </Accordion>
      <UnitDetailsPopup
        unit={unit}
        open={editingUnit}
        close={() => setEditingUnit(false)}
        save={(unitData) => saveUnit(unitData)}
        saving={savingUnitData}
      />
      <ReorderContentPopup
        open={reordering}
        close={() => setReordering(false)}
        contentArr={content}
        //TODO save in server
        save={(arr) =>
          setContent(
            arr.map((item, index) => {
              return {
                ...item,
                order: index + 1,
              };
            })
          )
        }
      />
      {ErrorPopupComponent}
    </>
  );
};

export default UnitInList;
