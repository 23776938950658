import AdminPage from "../components/admin/AdminPage";
import EnterScenarioMetadata from "../components/admin/course/EnterScenarioMetadata";

const EditScenario = () => {
  return (
    <AdminPage currentLocation="Edit scenario">
      <div className="prompt-generation-container">
        <h1>Edit scenario</h1>
        <div className="prompt-generation-section">
          <EnterScenarioMetadata />
        </div>
      </div>
    </AdminPage>
  );
};

export default EditScenario;
