import { Class, ExpandMore, Info, School, SwapVert } from "@mui/icons-material";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  IconButton,
  Typography,
} from "@mui/material";
import UnitInList from "./UnitInList";
import { useEffect, useState } from "react";
import CourseDetailsPopup from "./CourseDetailsPopup";
import ReorderContentPopup from "./ReorderContentPopup";
import axios from "axios";
import { URL } from "../../../tools/url";
import useErrorPopup from "../../../tools/hooks/showError";

const CourseInList = ({ course, setCourses }) => {
  const [editingNew, setEditingNew] = useState(false);
  const [newUnitName, setNewUnitName] = useState("");
  const [savingNew, setSavingNew] = useState(false);

  const [editingCourse, setEditingCourse] = useState(false);
  const [savingCourseData, setSavingCourseData] = useState(false);

  const [units, setUnits] = useState([]);
  const [reordering, setReordering] = useState(false);

  const [showError, ErrorPopupComponent] = useErrorPopup();

  const updateUnit = (unitData, isNew = false) => {
    setCourses((prev) =>
      prev.map((c) => {
        if (c.id === course.id) {
          return {
            ...c,
            units: isNew
              ? [...c.units, unitData]
              : c.units.map((u) => {
                  if (u.id === unitData.id) {
                    return unitData;
                  }
                  return u;
                }),
          };
        }
        return c;
      })
    );
  };

  const saveNewUnit = async () => {
    setSavingNew(true);
    try {
      const { data } = await axios.post(`${URL}/api/ceSaveUnit/`, {
        name: newUnitName,
        order: units.length + 1,
        parent_course_id: course.id,
      });
      updateUnit(data, true);
      setNewUnitName("");
      setEditingNew(false);
    } catch (err) {
      console.error(err);
      showError("Couldn't save unit");
    }
    setSavingNew(false);
  };

  const saveCourse = async (courseData) => {
    setSavingCourseData(true);
    try {
      const { data } = await axios.post(`${URL}/api/ceSaveCourse/`, {
        ...courseData,
      });
      setCourses((prev) =>
        prev.map((item) => {
          if (item.id === data.id) return data;
          return item;
        })
      );
    } catch (err) {
      console.error(err);
      showError("Couldn't save course");
    }
    setSavingCourseData(false);
  };

  useEffect(() => {
    setUnits(
      course?.units
        ? course.units.sort((a, b) => (a.order < b.order ? a : b))
        : []
    );
  }, [course]);

  return (
    <>
      <Accordion
        className="staff-course-in-list"
        style={{ margin: "10px 0", background: "#99a4ff" }}
      >
        <AccordionSummary expandIcon={<ExpandMore />}>
          <Typography className="staff-course-in-list-name">
            <School />
            COURSE: {course.name}
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <div className="staff-course-in-list-details">
            <div
              style={{
                margin: "5px 0",
                fontWeight: "bold",
                textDecoration: "underline",
              }}
            >
              DETAILS
            </div>
            <div className="staff-course-in-list-description">
              <Info />{" "}
              {course.description ?? (
                <i style={{ fontStyle: "italic" }}>No description</i>
              )}
            </div>
          </div>
          <div className="staff-course-in-list-edit-button-wrapper">
            <button
              className="prompt-generation-button"
              onClick={() => setEditingCourse(true)}
            >
              EDIT DETAILS FOR COURSE "{(course?.name ?? "").toUpperCase()}"
            </button>
          </div>
          <div className="staff-course-in-list-units">
            <div
              style={{
                margin: "15px 0 5px 0",
                fontWeight: "bold",
                textDecoration: "underline",
              }}
            >
              UNITS
            </div>
            {(units ?? []).map((unit) => (
              <UnitInList unit={unit} key={unit.id} updateUnit={updateUnit} />
            ))}
            {units.length >= 2 ? (
              <IconButton
                title="Reorder units"
                onClick={() => setReordering(true)}
                style={{
                  width: "30px",
                  height: "30px",
                  color: "white",
                  background: "#262D3B",
                }}
              >
                <SwapVert />
              </IconButton>
            ) : (
              <></>
            )}
            <div
              className="staff-course-in-list"
              style={{
                margin: "10px 0",
                background: "#ff9ac8",
                display: "flex",
                alignItems: "center",
                height: "50px",
                padding: "0 15px",
              }}
            >
              {editingNew ? (
                <div
                  className="staff-course-in-list-name"
                  style={{ display: "flex", alignItems: "center" }}
                >
                  <Class />
                  <input
                    placeholder="Enter unit name..."
                    value={newUnitName}
                    onChange={(e) => setNewUnitName(e.target.value)}
                    disabled={savingNew}
                    style={{ width: "775px" }}
                  ></input>
                  {savingNew ? (
                    <div
                      style={{
                        width: "200px",
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      <div
                        className="loader prompt-generation-loader"
                        style={{ height: "35px" }}
                      >
                        <div id="bar1" className="bar"></div>
                        <div id="bar2" className="bar"></div>
                        <div id="bar3" className="bar"></div>
                      </div>
                    </div>
                  ) : (
                    <>
                      <button
                        className="prompt-generation-button"
                        style={{
                          margin: "0 5px 0 0",
                          padding: "5px 10px",
                          background: false ? "#ccc" : "#0ae5a1",
                          border: "1px solid #282c34",
                          color: "#282c34",
                        }}
                        disabled={false}
                        onClick={saveNewUnit}
                      >
                        SAVE
                      </button>
                      <button
                        className="prompt-generation-button"
                        style={{
                          margin: 0,
                          padding: "5px 10px",
                          background: "#fa7d5f",
                          border: "1px solid #282c34",
                          color: "#282c34",
                        }}
                        onClick={() => {
                          setEditingNew(false);
                          setNewUnitName("");
                        }}
                      >
                        CANCEL
                      </button>
                    </>
                  )}
                </div>
              ) : (
                <button
                  className="prompt-generation-button"
                  style={{
                    margin: 0,
                    padding: "5px 10px",
                  }}
                  onClick={() => setEditingNew(true)}
                >
                  ADD A NEW UNIT
                </button>
              )}
            </div>
          </div>
        </AccordionDetails>
      </Accordion>
      <CourseDetailsPopup
        open={editingCourse}
        course={course}
        close={() => setEditingCourse(false)}
        save={(courseData) => saveCourse(courseData)}
        saving={savingCourseData}
      />
      <ReorderContentPopup
        open={reordering}
        close={() => setReordering(false)}
        contentArr={course.units}
        type="unit"
        save={(arr) =>
          saveCourse({
            ...course,
            units: arr.map((item, index) => {
              return {
                ...item,
                order: index + 1,
              };
            }),
          })
        }
      />
      {ErrorPopupComponent}
    </>
  );
};

export default CourseInList;
