import { Checkbox, FormControlLabel } from "@mui/material";
import { useState } from "react";

const EnterScenarioMetadata = () => {
  const [scenarioName, setScenarioName] = useState("");
  const [userInstructions, setUserInstructions] = useState("");
  const [isActive, setIsActive] = useState(false);
  const [botName, setBotName] = useState("");
  const [botStart, setBotStart] = useState("");
  return (
    <div>
      <div className="prompt-generation-input">
        <label htmlFor="scenario-name">Scenario name</label>
        <textarea
          className="prompt-generation-textarea"
          style={{ height: "fit-content" }}
          name="scenario-name"
          placeholder="Enter scenario name..."
          value={scenarioName}
          onChange={(e) => setScenarioName(e.target.value)}
        />
      </div>
      <br />
      <div className="prompt-generation-input">
        <label htmlFor="user-instructions">User instructions</label>
        <textarea
          className="prompt-generation-textarea"
          name="user-instructions"
          placeholder="Enter user instructions..."
          value={userInstructions}
          onChange={(e) => setUserInstructions(e.target.value)}
        />
      </div>
      <br />
      <div>
        <FormControlLabel
          control={
            <Checkbox
              checked={isActive}
              onChange={() => setIsActive((prev) => !prev)}
              sx={{
                "&.Mui-checked": {
                  color: "#0ae5a1",
                },
              }}
            />
          }
          label="Active"
          labelPlacement="start"
          sx={{ m: 0 }}
        />
      </div>
      <br />
      <div className="prompt-generation-input">
        <label htmlFor="bot-name">Bot name</label>
        <textarea
          className="prompt-generation-textarea"
          style={{ height: "fit-content" }}
          name="bot-name"
          placeholder="Enter bot name..."
          value={botName}
          onChange={(e) => setBotName(e.target.value)}
        />
      </div>
      <br />
      <div className="prompt-generation-input">
        <label htmlFor="bot-start">Bot start</label>
        <textarea
          className="prompt-generation-textarea"
          name="bot-start"
          placeholder="Enter bot start..."
          value={botStart}
          onChange={(e) => setBotStart(e.target.value)}
        />
      </div>
      <br />
    </div>
  );
};

export default EnterScenarioMetadata;
