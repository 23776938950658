import { Description, ExpandMore, QuestionAnswer } from "@mui/icons-material";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
} from "@mui/material";
import { useNavigate } from "react-router-dom";

const ScenarioInList = ({ scenario }) => {
  const navigate = useNavigate();
  return (
    <Accordion
      className="staff-course-in-list-lesson"
      style={{
        margin: "10px 0",
        background: "#ffd171",
      }}
    >
      <AccordionSummary expandIcon={<ExpandMore />}>
        <Typography className="staff-course-in-list-lesson-name">
          <QuestionAnswer /> SCENARIO: {scenario.name}
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        <div className="staff-course-in-list-scenario">
          <div
            className="staff-course-in-list-description"
            style={{
              backgroundColor: "#ffeeca",
            }}
          >
              <Description />{" "}
              {scenario.user_instructions ?? (
                <i style={{ fontStyle: "italic" }}>No user instructions</i>
              )}
          </div>
        </div>
        <div className="staff-course-in-list-edit-button-wrapper">
          <button
            className="prompt-generation-button"
            onClick={() =>
              navigate(`/admin/courses/edit-scenario/${scenario.id}`)
            }
          >
            EDIT SCENARIO "{(scenario?.name ?? "").toUpperCase()}"
          </button>
        </div>
      </AccordionDetails>
    </Accordion>
  );
};

export default ScenarioInList;
