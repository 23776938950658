import axios from "axios";
import { URL } from "../../../tools/url";
import { useState, useEffect } from "react";
import useErrorPopup from "../../../tools/hooks/showError";
import "../../../style/filterBar.css";
import Graph from "./Graph";
import { Line } from "recharts";
import { groupBy, fillNullDates } from "./KPIUtils.jsx";


const ConversationTurnsKPIs = ({ currentLocation, children }) => {
  const [userOptions, setUserOptions] = useState();
  const [metric, setMetric] = useState();
  const [graphData, setGraphData] = useState([]);
  const [submit, setSubmit] = useState(false);
  const [showError, ErrorPopupComponent] = useErrorPopup();
  const [user, setUser] = useState(null);
  const [showMessage, setShowMessage] = useState(false);
  const [buttonDisabled, setButtonDisabled] = useState(true)
  const [lines, setLines] = useState(<Line type="monotone" dataKey="Average" stroke="#82ca9d" />);

  // get all the users from the DB and insert them into the dropdown user select
  const fetchUsers = async () => {
    try {
      const { data } = await axios.get(`${URL}/api/getUsersList/`);
      setUserOptions(data?.users.map(user => {
        return (<option key={user.username} value={user.username}>{user.username}</option>)}));
    } catch (err) {
      console.error(err);
      showError("Couldn't get users");
    }
  };

  // send the given information to get the relevant information to show
  const handleSubmit = async (event) => {
    event.preventDefault();
    // initializations
    const graphDataArr = [];
    setGraphData(graphDataArr);
    setSubmit(false);
    const form = event.target;
    try {
      // get the relevant user scenarios given the user and status
      const data = await axios.get(`${URL}/api/getUserTurnsStats/?id=${form[0].value}&metric=${form[1].value}`);
      const turn_stats = data.data.user_stats;
      const users_num = form[0].value === "All users" ? form[0].length - 1 : 1;
      // group by date and count
      const events_by_date = groupBy(turn_stats, se => se.date);
      const dates = [...new Set(turn_stats.map(se => se.date))];
      for (const date of dates) {
        const events_in_date = events_by_date.get(date);
        let sum_events = 0;
        for (const event of events_in_date) {
          sum_events += event[form[1].value];
        }
        let events_avg = sum_events / events_in_date.length / users_num;
        graphDataArr.push({"name": date, "Average": events_avg});
      }
      if (graphDataArr.length) {
        fillNullDates(graphDataArr, dates, "ConversationTurns");
      }
      setGraphData(graphDataArr);
      if (graphDataArr.length) {
        setShowMessage(false);
        setSubmit(true);
      } else {
        setShowMessage(true);
      }
      console.log("data.user_turns_stats", data.data.user_stats);
    } catch (err) {
      console.error(err);
      showError("Couldn't show graph");
    }
  };

  // set the users list and skill list
  useEffect(() => {
    fetchUsers();
  }, []);

  // prevent illegal requests
  useEffect(() => {
    if (metric) {
      setButtonDisabled(false);
    } else {
      setButtonDisabled(true);
    }
    setSubmit(false);
  }, [metric]);

  return (
    <div className="conv-turns-wrapper">
      <form onSubmit={handleSubmit} className="flexbox-container">
        <div className="filter-item">
          <label className="filter-bar">Select user:</label>
          <select className="rectangle-object" name="selected_user" onChange={e => setUser(e.target.value)}>
            <option value="All users">- All users -</option>
            { userOptions }
          </select>
        </div>
        <div className="filter-item">
          <label className="filter-bar">Select metric:</label>
          <select className="rectangle-object" name="selected_metric" onChange={e => setMetric(e.target.value)}>
            <option value="" selected={true} disabled={true}>--- Please choose a metric ---</option>
            <option value="turns">Number of turns per conversation</option>
            <option value="time">Time to take a turn for the user</option>
          </select>
        </div>
        <button className="show-graph" type="submit" disabled={buttonDisabled}>Show turn statistics graph</button>
      </form>
      <Graph graphData={graphData} lines={lines} submit={submit} showMessage={showMessage} />
    </div>
  );
};

export default ConversationTurnsKPIs;
